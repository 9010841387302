import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import Layout from "../styles/layout";
import { Link, Box, Typography, Container } from "@mui/material";

function inlineLink(url, title) {
  return <Link href={url}>{title}</Link>;
}

const NotFoundPage = ({ props, location }) => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "img/error/title.jpg" }) {
        childImageSharp {
          doggy: gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `);

  let image = data.file.childImageSharp.doggy;

  return (
    <Layout location={location}>
      <Container>
        <Typography variant="h1">Page not found</Typography>
        <Typography>
          Congratulations, you found me, the guardian of the error page.
        </Typography>
        <Box height="24px"></Box>
        <Typography>
          If you like, you can stay here for a while and chill with me. You
          could also go back to {inlineLink("/", "tanaschita.com")}, maybe you
          are lucky and find what you were looking for in the first place.
        </Typography>
        <Box height="24px"></Box>
        <GatsbyImage
          image={image}
          alt="Photo of a dog relaxing in the sun"
          className={""}
        />
      </Container>
    </Layout>
  );
};

export default NotFoundPage;
